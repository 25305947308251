import * as Sentry from '@sentry/sveltekit';

import { ENV, SENTRY_DSN } from '$/env';

Sentry.init({
	environment: ENV,
	dsn: SENTRY_DSN,
	integrations: [Sentry.extraErrorDataIntegration({ depth: 10 })],
});

export const handleError = Sentry.handleErrorWithSentry();
